import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/Store";
import { setShowCollectionStatement } from "../../store/user-acceptances/UserAcceptancesSlice";

import { Heading1New, CollectionNoticeModal } from "ccp-common-ui-components";
import styled from "styled-components";

const CollectionParagraph = styled.p`
  font-weight: normal;
  font-size: 16px;
`;

const CollectionHeading = styled(Heading1New)`
  margin: 0;
`;

const CollectionHeaderContainer = styled.div`
  font-family: "Source Sans", sans-serif;
`;

export function CollectionStatementModalContainer() {
  const dispatch = useDispatch();

  const { showCollectionStatement } = useSelector(
    (state: RootState) => state.userAcceptances
  );

  const closeModal = () => {
    dispatch(setShowCollectionStatement(false));
  };

  return (
    <CollectionNoticeModal
      isOpen={showCollectionStatement}
      shouldCloseOnEsc={true}
      onRequestClose={() => closeModal()}
      closeHandler={() => closeModal()}
      titleComponent={
        <CollectionHeaderContainer>
          <CollectionHeading>Coles Collection Notice</CollectionHeading>
          <CollectionParagraph>Last updated July 2023</CollectionParagraph>
        </CollectionHeaderContainer>
      }
    />
  );
}
